.float-left {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.float-right {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}

.page-header {
  padding-bottom: 9px;
  border-bottom: 2px solid blue;
}

.page-footer {
  padding-top: 9px;
  border-top: 2px solid blue;
}

.mr-5 {
  margin-right: 20px;
}

@media (max-width: 920px) {
  .navbar-collapse {
    position: absolute;
    top: 90px; /* Adjust according to your Navbar height */
    right: 20px;
    background-color: white; /* Same as the navbar background color */
    width: 20%;
    text-align: center;
  }
}
